import React from "react"
import { PencilIcon} from "@heroicons/react/solid"
import { Link } from "gatsby"
import "/src/styles/logPage.css"

function Logs({ info }) {

  

  return (
    <>
      {info.map(log => {
        if (typeof window !== "undefined") {
          if (
            log.name === window.localStorage.user ||
            log.name === window.localStorage.user
          ) {
            return (
              <div key={log._id} className="grid grid-cols-12">
                <div className="col-span-10 col-start-2 md:col-span-10  md:col-start-1 ml-6">
                  <div class=" w-full py-4 px-8 bg-white shadow-lg rounded-lg my-3 md:my-6">
                    <div className="w-100 flex justify-end">
                    <Link to={`/logbook/${log._id}`}><span className="mr-5 z-2">
                      <button
                      height="10"
                      width="10"
                       
                      >
                        <PencilIcon
                          className="h-7 w-7 text-blue-900"
                          aria-hidden="true"
                        />
                      </button>
                    </span></Link>
                   
                    </div>
                    <div>
                      <p id="single_log" class="mt-2 text-gray-600">{log.report}</p>
                    </div>
                    <div class="flex justify-end mt-4">
                      <p class=" font-medium text-teal-800">{log.name}</p>
                    </div>
                    <div class="flex justify-end mt-1">
                      <p class="text-sm font-medium text-teal-800">
                        {log.date}
                      </p>
                    </div>
                  </div>{" "}
                </div>{" "}
              </div>
            )
          }
          else{
            return (
              <div key={log._id} className="grid grid-cols-12">
                <div className="col-span-10 col-start-2 md:col-span-10  md:col-start-1 ml-6">
                  <div class=" w-full py-4 px-8 bg-white shadow-lg rounded-lg my-3 md:my-6">
                    
                    <div>
                      <p id="single_log" class="mt-2 text-gray-600">{log.report}</p>
                    </div>
                    <div class="flex justify-end mt-4">
                      <p class=" font-medium text-teal-800">{log.name}</p>
                    </div>
                    <div class="flex justify-end mt-1">
                      <p class="text-sm font-medium text-teal-800">
                        {log.date}
                      </p>
                    </div>
                  </div>{" "}
                </div>{" "}
              </div>
            )
          }
        }
      })}
    </>
  )
}

export default Logs
