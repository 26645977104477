import React, { useState, useEffect } from "react"
import Layout from "../../components/layout"
import Axios from "axios"
import baseUrl from "../../components/baseUrl"
import Logs from "../../components/logs"
import { Link } from "gatsby"

function DailyLog() {
  const [logs, setLogs] = useState([])
  let dateObj = new Date()
  var month = dateObj.getUTCMonth() + 1 //months from 1-12
  var day = dateObj.getUTCDate()
  var year = dateObj.getUTCFullYear()
  let tempDate = day + "/" + month + "/" + year
  const [newDate, setNewDate] = useState(tempDate)

  useEffect(() => {
    Axios.get(`${baseUrl}/logs?date=${newDate}`).then(jsonRes => {
      setLogs(jsonRes.data)
    })
  }, [newDate])

  const getNewDate = e => {
    let dt = e.target.value
    let da = new Date(dt)
    let newday = `${da.getDate()}/${da.getMonth() + 1}/${da.getFullYear()}`
    setNewDate(newday)
  }

  return (
    <Layout route={"/logbook"}>
      <>
        <div className="logPage">
          <div className="grid grid-rows-1">
            <div className="grid grid-cols-12">
              <div className="col-span-9 md:col-span-2 col-start-1">
                {" "}
                <h2 className="p-6">LOGS</h2>
              </div>
              <div className="block col-span-3 col-start-10 md:hidden p-6">
                {" "}
                <Link to="/logbook/addLog">
                  <button
                    className="modalButton text-white active:bg-green-600  rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                  >
                    +
                  </button>
                </Link>
              </div>
              <div className=" col-span-10 col-start-2  md:col-span-3 md:col-start-7 p-6">
                <div className="flex items-center justify-center">
                  <div className="datepicker relative form-floating mb-3 xl:w-96">
                    <input
                      type="date"
                      className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                      placeholder="Select a date"
                      onChange={e => getNewDate(e)}
                    />
                  </div>
                </div>
              </div>
              <div className="hidden col-span-3 col-start-10  md:block p-6">
                {" "}
                <Link to="/logbook/addLog">
                  <button
                    className="modalButton text-white active:bg-green-600  rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                  >
                    +
                  </button>
                </Link>
              </div>
            </div>
          </div>
          {logs.length > 0 ? (
            <Logs info={logs} />
          ) : (
            <div className="flex justify-center my-9">No reports were submitted on {newDate}</div>
          )}
        </div>
      </>
    </Layout>
  )
}

export default DailyLog
